import styled from "styled-components";

import { ReactComponent as UserIcon } from "assets/icons/user.svg";

import Text from "components/Text";

const SectionHeading = styled.div`
  margin: 0 auto 56px;
`;

const TestimonialsContainer = styled.section`
  width: 100%;
  margin-bottom: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 48px;
`;

const QuoteContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserIconStyled = styled(UserIcon)`
  margin-right: 8px;
  background-color: rgb(64 64 64 / 1);
  border-radius: 50%;
  padding: 4px;
  height: 40px;
  width: 40px;
`;

const TestimonialsSection = () => {
  const TESTIMONIALS = [
    {
      name: "Assia A",
      message:
        "Ils ont organisé nos vacances de A à Z, on a juste eu à faire nos valises et profiter de nos vacances.",
    },
    {
      name: "Marthe M",
      message:
        "On voit clairement la différence, on profite mieux de notre temps libre, plus besoin de nous soucier des détails qui nous stressaient",
    },
    {
      name: "May S",
      message: "Service très professionnel et organisé, je recommande.",
    },
  ];

  return (
    <>
      <SectionHeading id="services">
        <Text
          fontSize="font18"
          fontWeight={500}
          color="yellow"
          textAlign="center"
          style={{ marginBottom: 8 }}
        >
          Témoignages
        </Text>
        <Text
          as="h2"
          fontSize="from32to20"
          fontWeight={600}
          textAlign="center"
          color="white"
          style={{ textTransform: "uppercase" }}
        >
          Les avis de nos clients
        </Text>
      </SectionHeading>

      <TestimonialsContainer>
        {TESTIMONIALS.map((testimonial) => {
          return (
            <QuoteContainer key={testimonial.name}>
              <Text
                fontSize="font16"
                style={{ fontStyle: "italic", marginBottom: 12 }}
              >
                “ {testimonial.message} ”
              </Text>
              <AuthorContainer>
                <UserIconStyled />
                <Text fontSize="font14" fontWeight={600} color="blue">
                  {testimonial.name}.
                </Text>
              </AuthorContainer>
            </QuoteContainer>
          );
        })}
      </TestimonialsContainer>
    </>
  );
};

export default TestimonialsSection;
