import { createGlobalStyle } from "styled-components";

export const LightTheme = {
  colors: {
    primary: "white",

    secondary: "black",
    secondary30: "rgba(0, 0, 0, 0.3)",

    yellow: "#ffdf59",
    blue: "#70ccff",
    grey: "rgb(163 163 163 / 400)",
  },
  fontSize: {
    font12: "12px",
    font14: "14px",
    font16: "16px",
    font20: "20px",
    font18: "18px",
    font24: "24px",
    font32: "32px",
    from20to14: "clamp(0.875rem, 3vw, 1.25rem)",
    from20to16: "clamp(1rem, 2vw, 1.25rem)",
    from24to14: "clamp(0.875rem, 3vw, 1.5rem)",
    from32to18: "clamp(1.125rem, 3vw, 2rem)",
    from32to20: "clamp(1.25rem, 4vw, 2rem)",
    from34to16: "clamp(1rem, 3vw, 2.125rem)",
    from34to24: "clamp(1.5rem, 4vw, 2.125rem)",
    from40to24: "clamp(1.5rem, 5vw, 2.5rem)",
    from40to30: "clamp(1.875rem, 5vw, 2.5rem)",
    from56to32: "clamp(2rem, 6vw, 3.5rem)",
    from100to52: "clamp(3.25rem, 10vw, 6.25rem)",
  },
  screens: {
    smallMobile: 375,
    mobile: 480,
    tablet: 768,
    smallDesktop: 1024,
    desktop: 1280,
    maxDesktop: 1440,
  },
};

export const DarkTheme = {
  colors: {
    primary: "#262626",

    secondary: "white",
    secondary30: "rgba(255, 255, 255, 0.3)",
    secondary60: "#40404099",
    secondary70: "#ffffff13",

    black: "#000000",
    yellow: "#ffdf59",
    blue: "#70ccff",
    grey: "rgb(163 163 163 / 400)",
  },
  fontSize: {
    font12: "12px",
    font14: "14px",
    font16: "16px",
    font18: "18px",
    font20: "20px",
    font24: "24px",
    font32: "32px",
    from20to14: "clamp(0.875rem, 3vw, 1.25rem)",
    from20to16: "clamp(1rem, 2vw, 1.25rem)",
    from24to14: "clamp(0.875rem, 3vw, 1.5rem)",
    from32to18: "clamp(1.125rem, 3vw, 2rem)",
    from32to20: "clamp(1.25rem, 4vw, 2rem)",
    from34to16: "clamp(1rem, 3vw, 2.125rem)",
    from34to24: "clamp(1.5rem, 4vw, 2.125rem)",
    from40to24: "clamp(1.5rem, 5vw, 2.5rem)",
    from40to30: "clamp(1.875rem, 5vw, 2.5rem)",
    from56to32: "clamp(2rem, 6vw, 3.5rem)",
    from100to52: "clamp(3.25rem, 10vw, 6.25rem)",
  },
  screens: {
    smallMobile: 375,
    mobile: 480,
    tablet: 768,
    smallDesktop: 1024,
    desktop: 1280,
    maxDesktop: 1440,
  },
};

export const GlobalStyles = createGlobalStyle`
  html, body {
    background: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.secondary};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
`;
