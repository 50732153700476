import styled from "styled-components";

import Layout from "./layouts/Layout";

import Text from "components/Text";
import Link from "components/Link";

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.screens.tablet}px`};
  margin: 64px auto 200px;
`;

const FakeLink = styled(Link)`
  &:hover {
    color: white !important;
  }
`;

const MentionsPage = () => {
  return (
    <Layout title="Mentions légales | M Conciergerie">
      <Text
        as="h1"
        fontSize="from40to30"
        fontWeight={500}
        color="white"
        textAlign="center"
      >
        Mentions légales
      </Text>

      <Container>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          Conformément à la loi du 29 juillet 1881, la société M CONCIERGERIE se
          réserve le droit de poursuivre toute personne qui serait auteur d’une
          injure ou d’une diffamation à son égard. Toute allégation ou
          imputation d’un fait qui porterait atteinte à l’honneur ou à la
          considération de la société M CONCIERGERIE, qu’elle soit effectuée par
          voie directe ou par voie de reproduction est susceptible de
          poursuites.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0 64px" }}
        >
          La marque M CONCIERGERIE, ainsi que l’ensemble des marques figuratives
          ou non et plus généralement toutes les autres marques, illustrations,
          images et logotypes dont il est fait usage dans les relations avec le
          Membre, qu’ils soient déposés ou non, sont et demeureront la propriété
          exclusive de M CONCIERGERIE. Toute reproduction totale ou partielle,
          modification ou utilisation de ces marques, illustrations, images et
          logotypes, pour quelque motif et sur quelque support que ce soit, sans
          accord exprès et préalable de M CONCIERGERIE, est strictement
          interdite. Il en est de même de toute combinaison ou conjonction avec
          toute autre marque, symbole, logotype et plus généralement tout signe
          distinctif destiné à former un logo composite. Il en est de même pour
          tout droit d’auteur, dessin, modèle et brevet qui sont la propriété de
          M CONCIERGERIE. Le Membre s’engage à faire un usage personnel et non
          commercial des informations contenues dans les documents et le site
          web diffusés par M CONCIERGERIE.
        </Text>

        <Text
          fontSize="font16"
          fontWeight={400}
          style={{ display: "flex", gap: 4 }}
        >
          Crédits photos :
          <FakeLink href="https://unsplash.com" isExternal>
            Unsplash
          </FakeLink>
        </Text>
      </Container>
    </Layout>
  );
};

export default MentionsPage;
