import styled from "styled-components";

import CheckIcon from "assets/icons/check.svg";
import CrossIcon from "assets/icons/close.svg";

import Text from "./Text";

const Container = styled.div`
  ${({ $isSelected }) => {
    if ($isSelected) {
      return `
        border: 2px solid rgba(255, 255, 255, 0.3);
        background: #242629;
        transform: scale(1.05);

        & img {
          background: #16161a;
        }
      `;
    }

    return `
      border: 2px solid transparent;
      background: #ffffff13;
      transform: scale(1);

      &:hover {
        transform: scale(1.02);
        background: #242629;
        border: 2px solid rgba(255, 255, 255, 0.3);
      }

      &:hover img {
        background: #16161a;
      }
    `;
  }}
  padding: 16px 16px 24px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.5s;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px auto 106px;
`;

const Price = styled(Text)`
  width: 60%;
  position: absolute;
  top: 96px;
  right: -2px;
  padding: 16px;
  background: #ffdf59;
  border-radius: 12px 0 0 12px;
`;

const DetailElement = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

const Icon = styled.img`
  padding: 4px;
  background: #242629;
  border-radius: 6px;
  margin-left: 24px;
  transition: all 0.5s;
`;

const PriceCard = ({ name, price, details, isSelected, onSelect }) => {
  return (
    <Container $isSelected={isSelected} onClick={onSelect}>
      <Text
        fontSize="font24"
        fontWeight={600}
        textAlign="center"
        color="secondary"
      >
        {name}
      </Text>

      <Divider />

      <Price fontSize="font20" fontWeight={600} color="black">
        {price}€ /mois
      </Price>

      {details.map((detail, index) => (
        <DetailElement
          key={`${name}-${detail.label}-${index}`}
          fontSize="text18"
          color="secondary"
        >
          {detail.label}

          <Icon
            src={detail.isChecked ? CheckIcon : CrossIcon}
            alt={detail.isChecked ? "check icon" : "cross icon"}
          />
        </DetailElement>
      ))}
    </Container>
  );
};

export default PriceCard;
