import { memo } from "react";
import { Link } from "react-router-dom";

const LinkCustom = ({
  href,
  isExternal = false,
  children,
  style,
  ...props
}) => {
  if (isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...style, display: "block", cursor: "pointer" }}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={href}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
      style={{ ...style, display: "block", cursor: "pointer" }}
      {...props}
    >
      {children}
    </Link>
  );
};

export default memo(LinkCustom);
