import styled from "styled-components";
import Text from "./Text";

const Container = styled.div`
  background: #ffffff13;
  padding: 16px;
  border: 2px solid transparent;
  border-radius: 8px;
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  cursor: default;

  &:hover {
    transform: scale(1.02);
    border: 2px solid rgb(64 64 64 / 1);
  }
`;

const Title = styled(Text)`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
`;

const ServiceCard = ({ icon, title, description }) => {
  return (
    <Container>
      <Title fontSize="font20" fontWeight={500} color="blue">
        {icon}
        {title}
      </Title>
      <Text
        fontSize="font16"
        textAlign="justify"
        fontWeight={500}
        style={{ margin: "auto 0" }}
      >
        {description}
      </Text>
    </Container>
  );
};

export default ServiceCard;
