import styled from "styled-components";

import Text from "components/Text";
import ServiceCard from "components/ServiceCard";

const SectionHeading = styled.div`
  margin: 0 auto 56px;
`;

const ServiceContainer = styled.section`
  width: 100%;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const ServicesSection = ({ services }) => {
  return (
    <>
      <SectionHeading id="services">
        <Text
          fontSize="font18"
          fontWeight={500}
          color="yellow"
          textAlign="center"
          style={{ marginBottom: 8 }}
        >
          Services
        </Text>
        <Text
          as="h2"
          fontSize="from32to20"
          fontWeight={600}
          textAlign="center"
          color="white"
          style={{ textTransform: "uppercase" }}
        >
          Ce que nous proposons
        </Text>
      </SectionHeading>

      <ServiceContainer>
        {services.map((service) => (
          <ServiceCard key={service.title} {...service} />
        ))}
      </ServiceContainer>
    </>
  );
};

export default ServicesSection;
