import styled from "styled-components";

import Layout from "./layouts/Layout";

import BookingLogo from "assets/img/booking-logo.webp";
import GoVoyagesLogo from "assets/img/go-voyages-logo.png";
import TripLogo from "assets/img/trip-logo.png";
import LavicadoLogo from "assets/img/lavicado-logo.png";
import OPrinceLogo from "assets/img/oprince-logo.png";

import { ReactComponent as TravelSvg } from "assets/icons/travel.svg";
import { ReactComponent as ExploreSvg } from "assets/icons/explore.svg";
import { ReactComponent as TravelExploreSvg } from "assets/icons/travel_explore.svg";
import { ReactComponent as SatisfySvg } from "assets/icons/satisfy.svg";

import HeroSection from "components/organisms/HeroSection";
import ServicesSection from "components/organisms/ServicesSection";
import PartnersBanner from "components/organisms/PartnerBanner";
import TestimonialsSection from "components/organisms/TestimonialsSection";

const SvgContainer = styled.div`
  width: 64px;
  height: 64px;
  padding: 12px;
  background: rgb(64 64 64 / 1);
  border-radius: 12px;
  border: 2px solid transparent;
  transition: all 0.5s;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const HomePage = () => {
  const PARTNERS = [
    BookingLogo,
    TripLogo,
    GoVoyagesLogo,
    LavicadoLogo,
    OPrinceLogo,
  ];

  const SERVICES = [
    {
      icon: (
        <SvgContainer>
          <TravelSvg />
        </SvgContainer>
      ),
      title: "Des voyages sur mesure",
      description:
        "Chez M Conciergerie, chaque séjour est une opportunité unique de découvrir le monde selon vos envies. Nous créons des expériences de voyage entièrement personnalisées pour répondre à vos besoins, que ce soit pour une escapade romantique, une aventure exotique ou un séjour culturel.",
    },
    {
      icon: (
        <SvgContainer>
          <ExploreSvg />
        </SvgContainer>
      ),
      title: "Des itinéraires uniques",
      description:
        "Nous ne nous contentons pas de vous transporter d'un point A à un point B. Chaque itinéraire est soigneusement conçu comme une symphonie de découvertes, afin que chaque moment soit exceptionnel et en accord avec vos attentes et votre budget.",
    },
    {
      icon: (
        <SvgContainer>
          <TravelExploreSvg />
        </SvgContainer>
      ),
      title: "Explorez l'inédit",
      description:
        "Avec M Conciergerie, le monde s'ouvre à vous sous un nouveau jour. Vivez des expériences authentiques dans des destinations inédites et séjournez dans des hébergements uniques que vous ne trouverez nulle part ailleurs.",
    },
    {
      icon: (
        <SvgContainer>
          <SatisfySvg />
        </SvgContainer>
      ),
      title: "Votre satisfaction avant tout",
      description:
        "Grâce à nos partenaires comme BOOKING, TRIP, et TOOSLA, nous vous garantissons un voyage qui correspond à vos rêves. Prêt à partir à l'aventure ? Chez M CONCIERGERIE, votre satisfaction est notre priorité.",
    },
  ];

  return (
    <Layout>
      <HeroSection />

      <PartnersBanner partners={PARTNERS} />

      <ServicesSection services={SERVICES} />

      <TestimonialsSection />
    </Layout>
  );
};

export default HomePage;
