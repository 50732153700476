import { useState, useEffect } from "react";
import styled from "styled-components";

// import { ThemeContext } from "contexts/ThemeContext";
import useWindowSize from "utils/useWindowSize";

import Logo from "assets/img/logo.png";
import BurgerMenuIcon from "assets/icons/burger-menu.svg";
import CrossIcon from "assets/icons/close.svg";

import Text from "./Text";
// import { ReactComponent as LightModeIcon } from "assets/icons/light-mode.svg";
// import { ReactComponent as DarkModeIcon } from "assets/icons/dark-mode.svg";
import Link from "./Link";

const Container = styled.nav`
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: blur(4px);
  background-color: #262626cc;
  width: calc(100% - 24px);
  max-width: 1440px;
  display: flex;
  align-items: center;
  border: 1px solid #40404066;
  border-radius: 43px;
  padding: 0px 24px;
  z-index: 999;
`;

const LogoImg = styled.img`
  width: clamp(3.5rem, 8vw, 4.75rem);
  height: clamp(3.5rem, 8vw, 4.75rem);
  border-radius: 50%;
  cursor: pointer;
`;

// const DarkModeSvg = styled(DarkModeIcon)`
//   cursor: pointer;
//   fill: rgb(163 163 163 / 400);
//   width: 28px;
//   height: 28px;
//   transition: all 0.2s;

//   &:hover {
//     fill: orangered;
//   }
// `;

// const LightModeSvg = styled(LightModeIcon)`
//   cursor: pointer;
//   fill: rgb(163 163 163 / 400);
//   width: 28px;
//   height: 28px;
//   transition: all 0.2s;

//   &:hover {
//     fill: orangered;
//   }
// `;

const FakeLink = styled(Text)`
  ${({ isActive, theme }) => `
    color: ${isActive ? theme.colors.blue : "rgb(163 163 163 / 400)"};

    &:hover {
      color: ${isActive ? theme.colors.blue : "white !important"};
    }
  `}
  transition: all 0.2s;
`;

const MobileMenuContainer = styled.div`
  ${({ $isOpen }) => `
    left: ${$isOpen ? 0 : "104%"};
  `}
  transition: left .5s;
  position: absolute;
  background: #262626;
  border: 1px solid #40404066;
  border-radius: 24px;
  width: 100%;
  height: calc(100vh - clamp(5.438rem, 13vw + 1rem, 8.438rem));
  top: calc(100% + 18px);
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Navbar = () => {
  // const { theme, setTheme } = useContext(ThemeContext);
  const { width } = useWindowSize();

  const [isOpen, setIsOpen] = useState(false);

  const isBiggerThan520 = width >= 520;

  const LINKS = [
    // { label: "Histoire", href: "/histoire" },
    { label: "Abonnements", href: "/abonnements" },
    // { label: "F.A.Q", href: "/foire-aux-questions" },
    // { label: "Jeu-concours", href: "/jeu-concours" },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isOpen]);

  return (
    <Container>
      <Link href="/" style={{ height: "clamp(3.5rem, 8vw, 4.75rem)" }}>
        <LogoImg src={Logo} alt="logo" />
      </Link>

      <div
        style={{
          marginLeft: "auto",
          marginRight: isBiggerThan520 ? 24 : 0,
          display: "flex",
          alignItems: "center",
          gap: 32,
        }}
      >
        {isBiggerThan520 ? (
          LINKS.map((link, idx) => {
            const isActive = window.location.pathname === link.href;

            return (
              <Link key={idx} href={link.href}>
                <FakeLink fontWeight={500} isActive={isActive}>
                  {link.label}
                </FakeLink>
              </Link>
            );
          })
        ) : (
          <img
            src={isOpen ? CrossIcon : BurgerMenuIcon}
            alt={isOpen ? "cross icon" : "burger menu icon"}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            style={{ width: 32 }}
          />
        )}
        {/* <div
          style={{
            height: 40,
            width: 1,
            background: "rgb(64 64 64 / 700)",
            marginLeft: 8,
          }}
        />
        {theme === "dark" ? (
          <DarkModeSvg onClick={() => setTheme("light")} />
        ) : (
          <LightModeSvg onClick={() => setTheme("dark")} />
        )} */}
      </div>

      {!isBiggerThan520 && (
        <MobileMenuContainer $isOpen={isOpen}>
          {LINKS.map((link, idx) => {
            const isActive = window.location.pathname === link.href;

            return (
              <Link key={idx} href={link.href}>
                <FakeLink
                  fontSize="font18"
                  fontWeight={isActive ? 500 : 400}
                  isActive={isActive}
                >
                  {link.label}
                </FakeLink>
              </Link>
            );
          })}
        </MobileMenuContainer>
      )}
    </Container>
  );
};

export default Navbar;
