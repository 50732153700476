import styled from "styled-components";

import Text from "components/Text";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 48px 0 112px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 48px;
  }
`;

const Logo = styled.img`
  width: auto;
  max-width: 180px;
  max-height: 80px;
  filter: grayscale(1);
  transition: all 0.4s;

  &:hover {
    filter: grayscale(0);
  }
`;

const PartnersBanner = ({ partners }) => {
  return (
    <>
      <Text
        fontSize="font20"
        fontWeight={500}
        color="white"
        textAlign="center"
        style={{ marginTop: 112 }}
      >
        Ces entreprises nous font confiance
      </Text>
      <Container>
        {partners.map((partner, idx) => {
          return (
            <Logo key={`partner-${idx}`} src={partner} alt={`partner-${idx}`} />
          );
        })}
      </Container>
    </>
  );
};

export default PartnersBanner;
