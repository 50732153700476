import { useState, useEffect } from "react";
import styled from "styled-components";

import HotelImg from "assets/img/hotel.jpg";
import AcceuilImg from "assets/img/accueil.jpg";
import SonnetteImg from "assets/img/sonnette.jpeg";
import ChevronDownIcon from "assets/icons/chevron-down.svg";

import Text from "components/Text";

const Container = styled.section`
  position: relative;
  width: 100%;

  max-width: calc(100% - 64px);
  height: calc(100dvh - 120px);
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: calc(100dvh - 150px);
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
`;

const Title = styled(Text)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1.5rem, 5vw, 3.5rem);
  text-transform: uppercase;
  padding: 16px;
  text-align: center;
  width: calc(100% - 32px);
  max-width: 780px;
  background: #40404099;
  border-radius: 16px;
  z-index: 2;
`;

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 240px;
  background: linear-gradient(
    0deg,
    rgba(38, 38, 38, 1) 50%,
    rgba(44, 182, 125, 0) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;

  @media (max-width: 768px) {
    height: 50%;
  }
`;

const ChevronDownImg = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 60px;
  background: #40404066;
  border-radius: 99px;
  animation: bounce 1.3s infinite;

  @keyframes bounce {
    0% {
      bottom: 55px;
    }

    40% {
      bottom: 40px;
    }

    100% {
      bottom: 55px;
    }
  }
`;

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [HotelImg, AcceuilImg, SonnetteImg];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Container>
      {images.map((image, index) => (
        <div
          key={`image-${index}`}
          className={index === currentIndex ? "slide fade" : "slide fadeout"}
        >
          <HeroImg src={image} alt={`home-${index}`} />
        </div>
      ))}

      <Title as="h1">Votre satisfaction est notre priorité</Title>
      <Gradient>
        <ChevronDownImg src={ChevronDownIcon} alt="chevron down" />
      </Gradient>
    </Container>
  );
};

export default HeroSection;
