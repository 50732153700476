import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { LightTheme, DarkTheme, GlobalStyles } from "utils/Theme";
import { ThemeContext } from "contexts/ThemeContext";

import HomePage from "pages/HomePage";
import MentionsPage from "pages/MentionsPage";
import ConfidentialityPage from "pages/ConfidentialyPage";
import RetractationPage from "pages/RetractationPage";
import TermsPage from "pages/TermsPage";
import PricesPage from "pages/PricesPage";

const App = () => {
  const [theme, setTheme] = useState("dark");

  // useEffect(() => {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     setTheme("dark");
  //   }
  // }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme === "light" ? LightTheme : DarkTheme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route exact path="/" element={<HomePage />} />

            <Route exact path="/abonnements" element={<PricesPage />} />

            <Route exact path="/mentions-legales" element={<MentionsPage />} />
            <Route
              exact
              path="/politique-de-confidentialite"
              element={<ConfidentialityPage />}
            />
            <Route
              exact
              path="/politique-de-retractation"
              element={<RetractationPage />}
            />
            <Route exact path="/termes-et-conditions" element={<TermsPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
