import styled from "styled-components";

import Layout from "./layouts/Layout";

import Text from "components/Text";

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.screens.tablet}px`};
  margin: 64px auto 200px;
`;

const TermsPage = () => {
  return (
    <Layout title="Terms et conditions | M Conciergerie">
      <Text
        as="h1"
        fontSize="from40to30"
        fontWeight={500}
        color="white"
        textAlign="center"
      >
        Termes & conditions
      </Text>

      <Container>
        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          I – CHAMP D’APPLICATION
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          1.1 La société M CONCIERGERIE propose à ses Clients des services de
          conciergerie privée dans le cadre d’un contrat appelé « Abonnement »,
          dont les caractéristiques sont définies à l’annexe1 des présentes.
          Dans ce cadre, elle met en relation, en qualité d’intermédiaire, ses
          Membres avec des fournisseurs de produits ou des prestataires de
          services partenaires. Les présentes Conditions Générales de Vente et
          d’Utilisation qui définissent les droits et obligations de M
          CONCIERGERIE et de ses Membres s’appliquent à l’ensemble des
          Abonnements, quelle que soit la formule choisie, sous réserve de leurs
          caractéristiques propres.
          <br />
          <br /> 1.2 Conformément à la réglementation en vigueur, les présentes
          Conditions Générales de Vente et d’Utilisation sont systématiquement
          communiquées à tout Membre lors de son adhésion, ou à tout moment sur
          simple demande, ou lors de toute modification.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          II – CONDITIONS D’ACCÈS AUX SERVICES
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          2.1 Les services de conciergerie privée fournis par M CONCIERGERIE
          sont réservés au Membre ainsi qu’au clients qui font des demandes
          ponctuel. Le Membre doit être âgé d’au moins 18 ans et avoir la
          capacité juridique de contracter pour accepter les présentes
          Conditions Générales de Vente et d’Utilisation.
          <br />
          <br /> 2.2 Toute inscription, demande ou commande passée, de manière
          directe ou indirecte, par quelque support que ce soit (téléphone;
          courrier; e-mail; SMS; messagerie instagram; WhatsApp ; etc.) auprès
          des services de conciergerie opérés par M CONCIERGERIE implique
          l’adhésion sans réserve des présentes Conditions Générales de Vente et
          d’Utilisation qui prévalent sur toutes autres conditions, à
          l’exception de celles qui ont été acceptées expressément par M
          CONCIERGERIE.
          <br />
          <br /> 2.3.1 Le ou les numéros de téléphone communiqué(s) lors de la
          souscription à l’une des formules d’Abonnement offertes par M
          CONCIERGERIE sont considérés comme appartenant au Membre ayant
          souscrit ledit Abonnement.
          <br /> 2.3.2 En conséquence, le ou les numéros de téléphone et la ou
          les adresse(s) mail communiquées à M CONCIERGERIE dans les conditions
          énoncées au point 2.3.1 des présentes, dont émane l’ensemble des
          demandes, commandes et acceptations est/sont considéré(s) comme
          authentifiant l’identité de son détenteur et utilisateur quel que soit
          le support utilisé (appels téléphoniques, SMS, des messages instagram,
          etc.), sans contestation possible de la part du Membre, sauf dans le
          cas de perte ou de vol et dans les modalités suivantes. Le Membre
          s’engage à communiquer à M CONCIERGERIE, dans les plus brefs délais,
          tout vol ou toute perte de téléphone portable lié au(x) numéro(s) de
          téléphone communiqué(s) lors de la souscription à l’Abonnement. Cette
          communication devra obligatoirement être accompagnée d’une déclaration
          de perte ou d’une déclaration de vol effectuée auprès des autorités
          compétentes. De même tout piratage de la ou les boîte(s) mail du
          Membre doit être signalé sans délai à M CONCIERGERIE.
          <br />
          <br /> 2.4 Chaque Membre est responsable de son Abonnement qui lui est
          strictement personnel, et doit prendre toutes les mesures nécessaires
          pour qu’aucune autre personne que lui-même ne l’utilise en son nom.
          Par exception, lors de la souscription à l’une des formules
          d’Abonnement ou par acte écrit postérieur, le Membre peut habiliter
          expressément une tierce personne à utiliser au nom et pour son compte
          son Abonnement, sous réserve d’acceptation de ce mandataire par M
          CONCIERGERIE.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          III – MODALITÉS D’ADHÉSION
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          3.1 Les demandes d’Abonnement sont soumises à une acceptation
          préalable de M CONCIERGERIE. M CONCIERGERIE se réserve la possibilité
          de refuser une demande d’Abonnement pour juste motif dans les termes
          de l’article L122-1 du code de la consommation. M CONCIERGERIE
          s’engage à notifier au Membre l’acceptation de son Abonnement par mail
          après la signature du contrat.
          <br />
          <br /> 3.2 Chaque Membre s’oblige à fournir à M CONCIERGERIE, au
          moment de l’adhésion, des informations exactes. M CONCIERGERIE ne
          pourra être tenue responsable des conséquences de toute information
          incomplète, erronée ou mensongère communiquée par le Membre. Chaque
          Membre doit communiquer à M CONCIERGERIE, de façon volontaire et
          spontanée et dans les plus brefs délais, tout changement intervenu
          dans ses données personnelles.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          IV – MODALITÉS D’UTILISATION DES SERVICES
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          4.1 Dès la prise d’effet de son Abonnement, le Membre peut transmettre
          ses requêtes par tous moyens (téléphone, sms, messagerie instagram, en
          utilisant le ou les numéros de téléphone et/ou la ou les adresses mail
          communiqués lors de la souscription de l’Abonnement par M CONCIERGERIE
          dans les conditions énoncées au point 2.3.1 des présentes. Les
          requêtes ainsi transmises par le Membre seront traitées par M
          CONCIERGERIE en sa qualité d’intermédiaire entre le Membre et les
          Partenaires. Les prestations sont réalisées par M CONCIERGERIE au nom
          et pour le compte du Membre auprès des Partenaires dans le cadre d’un
          mandat confié par le Membre à M CONCIERGERIE. Le mandat donné à M
          CONCIERGERIE est validé par l’acceptation par le Membre des présentes
          Conditions Générales de Vente et d’Utilisation, et s’applique aux
          demandes formulées auprès de M CONCIERGERIE par le Membre et exécutées
          dans le cadre et selon les modalités prévues aux présentes.
          <br />
          <br /> 4.2 Toute demande de prestation (telle que achats de bijoux,
          location et/ou achats de voiture, bateau et/ou jet privé, longs
          séjours hôteliers, chef privé à domicile, déménagement, package
          vacances, …) fera l’objet d’un devis soumis au Membre pour
          approbation, à l’exception des prestations de transports sèches
          (taxis, chauffeurs privés, train, avion de ligne). Toutefois, le
          Membre peut, à tout moment, demander à M CONCIERGERIE le montant de la
          prestation, quelle qu’elle soit. Les dépenses effectuées auprès des
          différents Partenaires sont faites au nom et pour le compte du Membre.
          <br />
          <br />
          4.3 Toute requête et toute acceptation effectuée par le Membre par
          tout moyen électronique (appel téléphonique, sms, messagerie
          instagram, WhatsApp et e-mail ) en utilisant le ou les numéros de
          téléphone ou adresses mail communiqués lors de la souscription à l’une
          des formules d’Abonnement offertes par M CONCIERGERIE, dans les
          conditions précisées au point 2.3.1 des présentes, ont la même force
          probante que l’écrit sur support papier au sens de l’article 1366 du
          Code civil.
          <br />
          <br /> 4.4 Les requêtes transmises à M CONCIERGERIE doivent respecter
          le cadre légal et éthique. M CONCIERGERIE se réserve le droit de pas
          donner suite à toute requête de nature illégale ou présentant un
          caractère contraire à la loi, aux bonnes mœurs et à la probité.
          L’accès aux produits et services de conciergerie peut faire l’objet de
          restrictions à l’égard de certaines personnes ou dans certains pays. M
          CONCIERGERIE ne traitera aucune requête en infraction avec la loi en
          vigueur dans le pays d’exécution.
          <br />
          <br /> 4.5 Les requêtes impliquant des prestations ou facturations
          supérieures à 100 €, ne seront définitivement prises en compte
          qu’après acceptation écrite ou orale du Membre à la demande de M
          CONCIERGERIE. Le Membre accepte donc expressément que les documents
          électroniques (téléphone, sms, messagerie instagram utilisant le ou
          les numéros communiqués lors de la souscription à l’une des formules
          d’Abonnement offertes par M CONCIERGERIE ; mails, etc. dans les
          conditions précisées au point 2.3.1 des présentes puissent servir de
          preuve à son acceptation, au sens de l’article 1366 du Code civil.
          <br />
          <br /> 4.6 Les commandes passées auprès de M CONCIERGERIE s’entendent
          sous réserve de disponibilité auprès des Partenaires. En cas
          d’indisponibilité d’un service, M CONCIERGERIE s’engage à fournir ses
          meilleurs efforts pour proposer un service équivalent. En cas de non
          acceptation par le Membre de la proposition de service équivalent, M
          CONCIERGERIE procédera à l’annulation pure et simple de la demande,
          sans que le Membre ne puisse réclamer une quelconque indemnité de
          quelque nature que ce soit à ce titre.
          <br />
          <br /> 4.7 M CONCIERGERIE communiquera auprès des Partenaires au nom
          du Membre.
          <br />
          <br /> 4.8 Grâce à son Abonnement, le Membre dispose d’un accès à un
          grand nombre d’avantages et de bénéfices négociés auprès des
          Partenaires sélectionnés par M CONCIERGERIE. Les Partenaires sont
          toutefois libres de modifier à tout moment leurs conditions
          commerciales et M CONCIERGERIE ne pourra être tenue responsable des
          changements effectués.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          V – PAIEMENT
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          5.1 Les produits et services acquis auprès des Partenaires seront
          directement facturés au Membre par M CONCIERGERIE. Le Membre peut
          demander et autoriser M CONCIERGERIE à utiliser sa carte de paiement à
          distance ou effectuer un virement équivalant au montant à payer afin
          de procéder au paiement direct d’un produit ou service d’un
          Partenaire. En communiquant les informations relatives à sa carte
          bancaire, le Membre autorise le Prestataire à débiter sa carte
          bancaire du montant correspondant au tarif validé préalablement. A
          cette fin, le Membre confirme qu’il est titulaire de la carte bancaire
          à débiter et que le nom figurant sur la carte bancaire est
          effectivement le sien. Le Membre s’engage à prendre toutes les mesures
          nécessaires auprès de sa ou de ses Banques, afin d’ajouter M
          CONCIERGERIE dans une liste de bénéficiaires de confiance, telle que
          prévue à l’Article 13 du Règlement délégué (UE) n° 2018/389 de la
          Commission européenne du 27 novembre 2017.
          <br />
          <br /> 5.2 La validation par le Membre – ou par l’une des personnes
          habilitées par ce dernier – effectuée par tout moyen (téléphone, sms,
          messagerie instagram utilisant le ou les numéros de téléphone
          communiqués lors de la souscription à l’une des formules d’Abonnement
          offertes par M CONCIERGERIE, e-mails en utilisant la ou les adresses
          mail communiquées lors de la souscription à l’Abonnement par M
          CONCIERGERIE ; etc. dans les conditions précisées au point 2.3 des
          présentes) – de la proposition transmise par M CONCIERGERIE en réponse
          à sa requête vaut acceptation de débit sur la carte de paiement du
          Membre du montant de la commande. Le Membre devra s’assurer que les
          détails de sa carte de paiement sont corrects et qu’il dispose des
          fonds disponibles sur son compte bancaire aux fins de pourvoir au
          paiement des prestations et/ou produits commandés. Dans le cas où le
          débit du prix serait impossible, la vente serait immédiatement résolue
          de plein droit et la commande annulée.
          <br />
          <br /> 5.3 Dès validation de la commande, le Membre s’engage à régler
          les prestations et/ou les produits commandés à réception de la facture
          correspondante. En cas de retard de paiement, M CONCIERGERIE ne
          saurait être tenue responsable de l’évolution des disponibilités et
          des tarifs des produits et/ou des services commandés.
          <br />
          <br /> 5.4 En cas de retard de paiement, des pénalités seront
          susceptibles d’être appliquées, soit par M CONCIERGERIE au taux de 12%
          calculé sur une base mensuelle, soit par le Partenaire concerné sur la
          base de ses propres conditions générales de vente et d’utilisation.
          <br />
          <br /> 5.5 Dans l’hypothèse où M CONCIERGERIE serait amenée à avancer
          le règlement d’une commande de prestations ou de produits, quels
          qu’ils soient, et pour quelque motif que ce soit, au nom et pour le
          compte d’un de ses Membres, des frais complémentaires (correspondant
          aux frais bancaires, frais de cartes de crédit, assurance, frais de
          gestion, frais de traitement) seront appliqués sur le montant de la
          commande HT. Le Membre s’engage à rembourser à M CONCIERGERIE, à
          première demande, le montant de la commande et des frais ainsi
          engagés. Le Membre peut demander à tout moment le montant des frais
          qui lui sont facturés.
          <br />
          <br /> 5.6 En cas de non-paiement de l’Abonnement et/ou de toute autre
          facture aux échéances convenues, M CONCIERGERIE est en droit
          d’adresser au Membre une lettre de mise en demeure de payer dans les 8
          jours à compter de la première présentation du courrier recommandé
          avec demande d’avis de réception. Si malgré cette mise en demeure, le
          Membre ne règle pas son Abonnement et/ou sa facture, M CONCIERGERIE
          pourra alors résilier l’Abonnement sans autre formalité. Cette
          résiliation ne pourra donner lieu à aucune demande de remboursement ou
          de versement d’une quelconque indemnité au bénéfice du Membre sortant.
          L’Abonnement aux services M CONCIERGERIE sera suspendu dès le 9ème
          jour suivant la mise en demeure, en l’absence de paiement de la part
          du Membre. En conséquence, le Membre n’aura plus accès aux services de
          M CONCIERGERIE jusqu’au parfait paiement de son Abonnement. Les
          factures non payées restent dues intégralement et M CONCIERGERIE se
          réserve le droit de poursuivre le Membre par toute voie de droit pour
          obtenir leur paiement avec exigibilité à titre de pénalité d’une
          indemnité égale à 15 % des sommes dues, outre les intérêts légaux et
          les frais judiciaires éventuels. De plus, il est rappelé que pour les
          Membres professionnels, en application des articles D. 441-6 et D.
          441-5 du Code de commerce, tout retard de paiement entraîne de plein
          droit, outre les pénalités de retard, une obligation pour le débiteur
          de payer une indemnité forfaitaire de 40€ pour frais de recouvrement.
          <br />
          <br />
          5.7 En cas de non utilisation des prestations liées à un Abonnement,
          aucun remboursement de ce dernier ne pourra être demandé.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          VI – SERVICES PARTICULIERS
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          6.1 Shopping : Sur demande du Membre, M CONCIERGERIE peut effectuer
          des achats en son nom. Dans ce cas, des frais de course ainsi que des
          frais d’envoi seront appliqués.
          <br />
          <br /> 6.2 Restaurants et Clubs : Pour certaines réservations de clubs
          et de restaurants le Membre autorise M CONCIERGERIE à utiliser sa
          carte de paiement pour garantir les réservations.
          <br />
          <br /> 6.3 Billetterie : M CONCIERGERIE s’engage à mettre en œuvre ses
          meilleurs moyens aux fins de satisfaire les demandes de billets du
          Membre pour tous les évènements auprès de ses Partenaires. Dans le cas
          où l’évènement affiche complet dans les billetteries traditionnelles,
          M CONCIERGERIE pourra faire appel à des prestataires spécialisés. Par
          conséquent, les prix proposés sont susceptibles d’évoluer à tout
          moment et être différents de la valeur faciale mentionnée sur les
          billets en raison de la majoration liée à des frais d’agence, de
          gestion et/ou administratifs. Toute demande de réservation confirmée
          est ferme et définitive. En conséquence, les places réservées ne
          peuvent être ni annulées, ni reportées, ni modifiées, ni échangées, ni
          remboursées. Le Membre est tenu de vérifier la date et l’heure du
          spectacle ou de l’évènement, le producteur ou l’organisateur étant
          susceptible de les modifier sans préavis. M CONCIERGERIE n’est pas
          responsable de la perte des billets ou de leur mauvais acheminement
          par voie postale. De même M CONCIERGERIE ne peut en aucun cas être
          tenue responsable de l’annulation ou du report d’un spectacle ou d’un
          évènement. En cas d’annulation ou report par l’artiste, le producteur
          ou l’organisateur, quelle que soit la circonstance, M CONCIERGERIE ne
          pourra être tenue qu’au remboursement de la valeur faciale des
          billets, sous réserve de l’obtention du remboursement auprès du
          producteur ou de l’organisateur.
          <br />
          <br /> 6.4 Taxis/Chauffeurs privés : En cas d’absence du Membre au
          rendez-vous prévu au moment de la commande et plus généralement si le
          taxi ou le chauffeur privé ne charge pas la course du fait du Membre
          (« non-charge »), ce dernier sera redevable du montant de la course
          sauf meilleur accord obtenu par M CONCIERGERIE auprès du Partenaire.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          VII – OBLIGATIONS DE M CONCIERGERIE
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          7.1 M CONCIERGERIE s’engage à répondre aux requêtes formulées par le
          Membre, quelles qu’elles soient, en un minimum de temps et à lui
          fournir ses conseils en lien avec celles-ci.
          <br />
          <br /> 7.2 M CONCIERGERIE agit comme un intermédiaire et se charge de
          représenter le Membre auprès des Partenaires.
          <br />
          <br /> 7.3 De par la nature même de son activité, M CONCIERGERIE est
          tenue à une obligation de moyens et non de résultats.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          VIII – RESPONSABILITÉS
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          8.1 Le Membre reconnaît et accepte que M CONCIERGERIE agisse selon les
          demandes telles qu’elles sont exprimées dans les requêtes qu’il
          transmet. M CONCIERGERIE ne pourra donc être tenue responsable en cas
          d’erreur dont le Membre serait, de quelque façon que ce soit, à
          l’origine. M CONCIERGERIE apporte le plus grand soin dans le choix et
          la sélection de ses Partenaires. Toutefois, elle ne peut être tenue
          responsable des agissements d’un Partenaire. M CONCIERGERIE est
          autorisée à annuler ou refuser les commandes d’un Membre avec lequel
          existerait un litige.
          <br />
          <br /> 8.2 M CONCIERGERIE agissant en qualité d’intermédiaire tenu
          d’une obligation de moyens, elle ne saurait encourir, quel que soit le
          motif, aucune indemnité de quelque nature que ce soit, relative aux
          produits ou services commandés pour le compte de ses Membres. En
          conséquence, toute demande de dédommagement ou toute autre demande de
          réparation relative au produit ou service commandé par l’intermédiaire
          de M CONCIERGERIE devra être adressée au Partenaire en cause, qui
          étudiera son bien-fondé et en assumera la responsabilité. Toutefois, M
          CONCIERGERIE apportera aide et assistance au(x) Membre(s) dans le
          cadre de la relation de ces derniers avec le Partenaire. M
          CONCIERGERIE ne pourra être tenue responsable de l’inexécution de ses
          obligations en cas de force majeure (perturbations, grève des
          transports, des communications ou des services postaux, inondation,
          incendie ou panne informatique, pandémie, etc.) ainsi que la définit
          le Code Civil et les tribunaux.
          <br />
          <br /> 8.3 Compte tenu de sa qualité d’intermédiaire, M CONCIERGERIE
          ne saurait voir engagée sa responsabilité au titre de l’inexécution
          totale ou partielle des services commandés au nom et pour le compte du
          Membre auprès des Partenaires. En outre, M CONCIERGERIE ne peut
          garantir, conformément aux dispositions légales, le Membre, contre
          tout défaut de conformité des biens et tout vice caché, provenant d’un
          défaut de conception ou de réalisation des biens fournis par un
          Partenaire et les rendant impropres à l’usage auquel ils étaient
          destinés. En cas de revendication de quelque nature que ce soit, le
          Membre devra s’adresser directement aux Partenaires concernés.
          <br />
          <br /> 8.4 La responsabilité de M CONCIERGERIE sera en tout état de
          cause limitée au montant de l’Abonnement aux services au moment du
          litige.
          <br />
          <br /> 8.5 En cas de non-respect des présentes Conditions Générales de
          Vente et d’Utilisation, la responsabilité du Membre pourra être
          engagée.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          IX – CONFIDENTIALITÉ ET PROTECTION DES INFORMATIONS PERSONNELLES
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          Les données collectées au moment de l’inscription – tels que nom,
          prénom, adresse, numéro de téléphone, adresse électronique et
          coordonnées bancaires – sont conservées avec l’autorisation du Membre
          et feront l’objet d’un traitement dans le respect des dispositions du
          présent point et de la législation applicable dans ce domaine.
          <br /> M CONCIERGERIE s’engage à respecter scrupuleusement la
          confiance que ses Membres lui accordent et à garantir une stricte
          confidentialité des informations, renseignements, données auxquels
          elle a accès dans le cadre de l’exercice de son mandat. En corrélation
          avec son éthique et sa déontologie, M CONCIERGERIE protège les
          informations personnelles que ses Membres peuvent être amenés à lui
          communiquer dans le cadre de leur Abonnement.
          <br /> Conformément aux obligations légales, et en conformité avec la
          CNIL, M CONCIERGERIE conserve ces informations dans les conditions
          légales.
          <br /> Les informations personnelles collectées seront conservées
          aussi longtemps que nécessaire jusqu’à la fin de la durée de
          l’Abonnement souscrit par le Membre, sauf si :<br /> a) une durée de
          conservation plus longue est autorisée ou imposée par une disposition
          légale ou règlementaire ;<br /> b) le Membre a exercé l’un des droits
          qui lui sont reconnus par la législation.
          <br /> Dans tous les cas, les données personnelles du Membre seront
          conservées pour une durée qui ne saurait excéder 36 mois à partir de
          la date de fin du dernier Abonnement souscrit par le Membre.
          <br /> L’accès aux données personnelles des Membres est strictement
          limité au personnel de M CONCIERGERIE habilité à les traiter en raison
          de ses fonctions. Les données personnelles ne sont utilisées que dans
          le cadre des finalités pour lesquelles les Membres les ont
          communiquées.
          <br /> L’utilisation de ces informations personnelles est réservée à
          un usage strictement interne (gestion des commandes, livraison,
          facturation, suivi de la solvabilité ainsi que toutes les études
          marketing interne et la publicité personnalisée). M CONCIERGERIE
          s’engage à ne pas vendre, louer, céder ou donner accès à des tiers aux
          données sans consentement préalable du Membre, à moins d’y être
          contraints en raison d’un motif légitime constitué par une contrainte
          légale ou judiciaire (obligation légale, lutte contre la fraude ou
          l’abus, exercice des droits de la défense, etc.).
          <br /> Toutefois, le Membre autorise M CONCIERGERIE à partager ses
          informations personnelles avec les Partenaires de M CONCIERGERIE si
          cela est nécessaire en vue de la gestion des commandes, de la
          livraison, de la facturation, du suivi de la solvabilité ainsi que de
          toutes les études marketing interne et de la publicité personnalisée.
          En cas d’exercice, de la part du Membre, de son droit d’accès, de
          rectification, d’effacement, de limitation, de portabilité ou
          d’opposition au traitement de ses données pour un motif légitime, cela
          sera notifié aux Partenaires concernés.
          <br /> Conformément à l’article 27 de la Loi Nº 78-17 du 6 janvier
          1978 modifiée, relative à l’informatique, aux fichiers et aux
          libertés, et au Règlement européen n°2016/679/UE du 27 avril 2016
          (applicable dès le 25 mai 2018), les Membres disposent d’un droit
          d’accès, de rectification, d’effacement, de limitation et de
          portabilité, ainsi que d’un droit d’opposition au traitement de ces
          données pour un motif légitime.
          <br /> Ces droits peuvent être exercés par les Membres, justifiant de
          leur identité, par l’un des moyens suivants :<br /> – en écrivant à
          l’adresse suivante : 78, avenue des champs élysées , 75008 PARIS ;
          <br /> – par téléphone au 06 16 50 72 13 (du lundi au vendredi de 9
          heures à 18 heures et les jours fériés de 14 heures à 18 heures ;
          fermeture le week end), appel non surtaxé.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          X – DIFFAMATION
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          Conformément à la loi du 29 juillet 1881, la société M CONCIERGERIE se
          réserve le droit de poursuivre toute personne qui serait auteur d’une
          injure ou d’une diffamation à son égard. Toute allégation ou
          imputation d’un fait qui porterait atteinte à l’honneur ou à la
          considération de la société M CONCIERGERIE, qu’elle soit effectuée par
          voie directe ou par voie de reproduction est susceptible de
          poursuites.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          XI – PROTECTION DE LA MARQUE ET DES DROITS DE M CONCIERGERIE
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          9.1 La marque M CONCIERGERIE, ainsi que l’ensemble des marques
          figuratives ou non et plus généralement toutes les autres marques,
          illustrations, images et logotypes dont il est fait usage dans les
          relations avec le Membre, qu’ils soient déposés ou non, sont et
          demeureront la propriété exclusive de M CONCIERGERIE. Toute
          reproduction totale ou partielle, modification ou utilisation de ces
          marques, illustrations, images et logotypes, pour quelque motif et sur
          quelque support que ce soit, sans accord exprès et préalable de M
          CONCIERGERIE, est strictement interdite. Il en est de même de toute
          combinaison ou conjonction avec toute autre marque, symbole, logotype
          et plus généralement tout signe distinctif destiné à former un logo
          composite. Il en est de même pour tout droit d’auteur, dessin, modèle
          et brevet qui sont la propriété de M CONCIERGERIE.
          <br />
          <br /> 9.2 Le Membre s’engage à faire un usage personnel et non
          commercial des informations contenues dans les documents et le site
          web diffusés par M CONCIERGERIE.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          XII – ACCEPTATION DU MEMBRE
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ marginBottom: 48 }}
        >
          10.1 Les présentes Conditions Générales de Vente et d’Utilisation sont
          expressément agréés et acceptées par le Membre, qui déclare et
          reconnaît en avoir une parfaite connaissance, et renonce, de ce fait,
          à se prévaloir de tout document contradictoire et, notamment, ses
          propres conditions générales d’achat, qui seront inopposables au
          Prestataire, même s’il en a eu connaissance.
          <br />
          <br /> 10.2 Toute abstention de la part de M CONCIERGERIE de se
          prévaloir d’une clause des présentes Conditions Générales de Vente et
          d’Utilisation ne peut aucunement être considérée comme une
          renonciation implicite à cette clause.
          <br />
          <br /> 10.3 Le fait qu’une des dispositions des présentes Conditions
          Générales de Vente et d’Utilisation devienne nulle, inopposable,
          caduque, illégale ou inapplicable ne pourra, en aucun cas, remettre en
          cause la validité des autres dispositions.
          <br />
          <br /> 10.4 M CONCIERGERIE se réserve la possibilité d’adapter ou de
          modifier à tout moment les présentes Conditions Générales de Vente et
          d’Utilisation. Dans ce cas, elle en informera les Membres avec un
          préavis de 2 mois.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={500}
          color="blue"
          textAlign="justify"
          style={{ marginBottom: 24 }}
        >
          XIII - SIGNATURE ÉLECTRONIQUE
        </Text>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          M CONCIERGERIE vous propose de signer électroniquement les documents
          de souscription des présentes Conditions Générales de vente et
          d’utilisation ainsi que le contrat d’abonnement. Les documents signés
          électroniquement ont la même valeur juridique qu’un document signé sur
          papier.
        </Text>
      </Container>
    </Layout>
  );
};

export default TermsPage;
