import { useState } from "react";
import styled from "styled-components";

import Layout from "./layouts/Layout";

import Text from "components/Text";
import PriceCard from "components/PriceCard";
import Link from "components/Link";

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.screens.desktop}px`};
  margin: 64px auto 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 24px;
`;

const BlueText = styled.span`
  color: #70ccff;
`;

const FakeBtn = styled(Link)`
  ${({ $isActive }) => {
    if ($isActive) {
      return ``;
    }

    return `
      pointer-events: none;
    `;
  }}

  background: black;
  padding: 12px 24px;
  border-radius: 8px;
  border: 2px solid transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.3);
  }
`;

const PricesPage = () => {
  const [isSelected, setIsSelected] = useState(-1);

  const SUBSCRIPTIONS = [
    {
      name: "GOLD",
      price: 199.99,
      details: [
        { label: "Concierge privé", isChecked: true },
        { label: "Partout dans le monde", isChecked: true },
        { label: "Disponible par mail à tout moment", isChecked: true },
        {
          label: "Accès aux offres exclusives et privilèges membres",
          isChecked: true,
        },
        {
          label: "Disponible par téléphone du lundi au vendredi de 8h à 18h",
          isChecked: true,
        },
        { label: "Nombre de demandes illimité", isChecked: false },
        { label: "Invitations VIP", isChecked: false },
        {
          label: "Valable pour le titulaire et 1 personne de son choix",
          isChecked: false,
        },
      ],
      link: "https://buy.stripe.com/cN26oX0qPa8PctGeUX",
    },
    {
      name: "DIAMOND",
      price: 1499.99,
      details: [
        { label: "Concierge privé", isChecked: true },
        { label: "Partout dans le monde", isChecked: true },
        { label: "Disponible par mail à tout moment", isChecked: true },
        {
          label: "Accès aux offres exclusives et privilèges membres",
          isChecked: true,
        },
        {
          label: "Disponible par téléphone du lundi au vendredi de 8h à 18h",
          isChecked: true,
        },
        { label: "Nombre de demandes illimité", isChecked: true },
        { label: "Invitations VIP", isChecked: true },
        {
          label: "Valable pour le titulaire et 1 personne de son choix",
          isChecked: true,
        },
      ],
      link: "https://buy.stripe.com/5kA8x54H580H9hu149",
    },
    {
      name: "PLATINUM",
      price: 399.99,
      details: [
        { label: "Concierge privé", isChecked: true },
        { label: "Partout dans le monde", isChecked: true },
        { label: "Disponible par mail à tout moment", isChecked: true },
        {
          label: "Accès aux offres exclusives et privilèges membres",
          isChecked: true,
        },
        {
          label: "Disponible par téléphone du lundi au vendredi de 8h à 18h",
          isChecked: true,
        },
        { label: "Nombre de demandes illimité", isChecked: true },
        { label: "Invitations VIP", isChecked: false },
        {
          label: "Valable pour le titulaire et 1 personne de son choix",
          isChecked: false,
        },
      ],
      link: "https://buy.stripe.com/eVa28H0qP0yfdxK4gk",
    },
  ];

  return (
    <Layout title="Abonnements | M Conciergerie">
      <Text
        as="h1"
        fontSize="from40to30"
        fontWeight={500}
        color="white"
        textAlign="center"
        style={{ maxWidth: 500, margin: "0 auto 56px" }}
      >
        Découvre l'abonnement adapté à tes besoins
      </Text>

      <Text
        fontSize="font16"
        fontWeight={500}
        textAlign="center"
        color="white"
        style={{ maxWidth: 820, margin: "0 auto" }}
      >
        Chez M Conciergerie, nous avons conçu des abonnements de conciergerie
        qui s'adaptent à <BlueText>vos besoins</BlueText> et à{" "}
        <BlueText>votre style de vie</BlueText>. <br />
        <br /> Que vous soyez un particulier recherchant{" "}
        <BlueText>des services sur mesure</BlueText>, un professionnel
        nécessitant <BlueText>une assistance quotidienne</BlueText>, ou
        simplement curieux de découvrir ce que notre conciergerie peut vous
        offrir, nous avons une formule pour vous. <br />
        <br /> Rejoignez-nous et laissez-nous <BlueText>
          simplifier
        </BlueText>{" "}
        votre quotidien avec des services personnalisés et de qualité.
      </Text>

      <Container>
        {SUBSCRIPTIONS.map((subscription, index) => {
          return (
            <PriceCard
              key={subscription.name}
              {...subscription}
              isSelected={isSelected === index}
              onSelect={() => setIsSelected(index)}
            />
          );
        })}
      </Container>

      <FakeBtn
        href={SUBSCRIPTIONS[isSelected]?.link ?? ""}
        isExternal={true}
        $isActive={isSelected >= 0}
        style={{ width: "max-content", margin: "0 auto 120px" }}
      >
        <Text
          fontSize="font20"
          fontWeight={600}
          color="white"
          style={{ width: "max-content" }}
        >
          Souscrire
        </Text>
      </FakeBtn>
    </Layout>
  );
};

export default PricesPage;
