import styled from "styled-components";

import InstagramIcon from "assets/img/instagram.webp";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";

import Text from "./Text";
import Link from "./Link";

const Container = styled.footer`
  background: rgb(23 23 23 / 900);
  padding: 32px 24px 48px;
`;

const Wrapper = styled.div`
  ${({ theme: { screens } }) => `
    max-width: ${screens.maxDesktop}px;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 32px;
  margin: 32px 0 56px;

  @media (max-width: 768px) {
  }
`;

const FakeLink = styled(Text)`
  transition: all 0.2s;

  &:hover {
    color: white !important;
    text-decoration: underline !important;
  }
`;

const Footer = () => {
  const LINKS = [
    { label: "Mentions légales", href: "/mentions-legales" },
    {
      label: "Politique de confidentialité",
      href: "/politique-de-confidentialite",
    },
    { label: "Politique de rétractation", href: "/politique-de-retractation" },
    { label: "Termes & conditions", href: "/termes-et-conditions" },
  ];

  return (
    <Container>
      <Wrapper>
        <IconsWrapper>
          <Link
            href="tel:+33745600125"
            isExternal
            style={{ width: 32, height: 32 }}
          >
            <PhoneIcon style={{ width: "100%", height: "100%" }} />
          </Link>
          <Link
            href="mailto:contact@m-conciergerie.com"
            isExternal
            style={{ width: 34, height: 34 }}
          >
            <MailIcon style={{ width: "100%", height: "100%" }} />
          </Link>
          <Link
            href="https://www.instagram.com/m.conciergerie_?igsh=am1sank5M2hyNzBi"
            isExternal
            style={{ width: 28, height: 28 }}
          >
            <img
              src={InstagramIcon}
              alt="instagram"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
        </IconsWrapper>

        <LinksWrapper>
          {LINKS.map((link) => {
            const isActive = window.location.pathname.includes(link.href);

            return (
              <Link key={link.label} href={link.href}>
                <FakeLink
                  fontSize="font14"
                  color={isActive ? "white" : "grey"}
                  style={{ textDecoration: isActive ? "underline" : "none" }}
                >
                  {link.label}
                </FakeLink>
              </Link>
            );
          })}
        </LinksWrapper>

        <Text fontSize="font14" textAlign="center">
          M Conciergerie © {new Date().getFullYear()}, tous droits réservés
        </Text>
      </Wrapper>
    </Container>
  );
};

export default Footer;
