import { useEffect } from "react";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

const Container = styled.main`
  width: 100%;
  max-width: 1440px;
  margin: 120px auto 0;
  min-height: calc(100dvh - 120px);

  @media (max-width: 1440px) {
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    margin: 88px auto 0;
  }
`;

const Layout = ({
  title = "M Conciergerie",
  description = "Site de conciergerie prestigieuse ayant pour but de vous simplifier l'organisation de votre quotidien à travers nos divers services.",
  children,
  withFooter = true,
}) => {
  useEffect(() => {
    document.title = title;
    document.getElementsByTagName("META")[3].content = description;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />

      <Container>{children}</Container>

      {withFooter && <Footer />}

      <Analytics />
    </>
  );
};

export default Layout;
