import styled from "styled-components";

import Layout from "./layouts/Layout";

import Text from "components/Text";

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.screens.tablet}px`};
  margin: 64px auto 200px;
`;

const RetractationPage = () => {
  return (
    <Layout title="Politique de rétractation | M Conciergerie">
      <Text
        as="h1"
        fontSize="from40to30"
        fontWeight={500}
        color="white"
        textAlign="center"
      >
        Politique de rétractation
      </Text>

      <Container>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          Le CONTRAT est conclu pour une durée indéterminée.
          <br />
          <br /> Toute résiliation et rétractation du présent CONTRAT demeure
          subordonnée à un Délai de Préavis de 14 jours à compter de la
          signature des présentes (ci-après le « Délai de Préavis »).
          <br />
          <br /> Toute résiliation intervenant en violation de ce Délai de
          Préavis ne saurait faire grief aux « Modalités de facturation »
          prévues à l’Article 2 du présent CONTRAT.
          <br />
          <br /> La résiliation intervenant en violation du Délai de Préavis
          sera, sans procédure supplémentaire nécessaire, appliquée sur le mois
          calendaire suivant.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          Conformément à l’article 27 de la Loi Nº 78-17 du 6 janvier 1978
          modifiée, relative à l’informatique, aux fichiers et aux libertés, et
          au Règlement européen n°2016/679/UE du 27 avril 2016 (applicable dès
          le 25 mai 2018), les Membres disposent d’un droit d’accès, de
          rectification, d’effacement, de limitation et de portabilité, ainsi
          que d’un droit d’opposition au traitement de ces données pour un motif
          légitime.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          Ces droits peuvent être exercés par les Membres, justifiant de leur
          identité, par l’un des moyens suivants :<br /> – en écrivant à
          l’adresse suivante : 78, avenue des champs élysées , 75008 PARIS ;
          <br /> – par téléphone au 06 16 50 72 13 (du lundi au vendredi de 9
          heures à 18 heures et les jours fériés de 14 heures à 18 heures ;
          fermeture le week-end), appel non surtaxé
        </Text>
      </Container>
    </Layout>
  );
};

export default RetractationPage;
