import styled from "styled-components";

import Layout from "./layouts/Layout";

import Text from "components/Text";

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.screens.tablet}px`};
  margin: 64px auto 200px;
`;

const ConfidentialityPage = () => {
  return (
    <Layout title="Politique de confidentialité | M Conciergerie">
      <Text
        as="h1"
        fontSize="from40to30"
        fontWeight={500}
        color="white"
        textAlign="center"
      >
        Politique de confidentialité
      </Text>

      <Container>
        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          Les données collectées au moment de l’inscription – tels que nom,
          prénom, adresse, numéro de téléphone, adresse électronique et
          coordonnées bancaires – sont conservées avec l’autorisation du Membre
          et feront l’objet d’un traitement dans le respect des dispositions du
          présent point et de la législation applicable dans ce domaine.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          M CONCIERGERIE s’engage à respecter scrupuleusement la confiance que
          ses Membres lui accordent et à garantir une stricte confidentialité
          des informations, renseignements, données auxquels elle a accès dans
          le cadre de l’exercice de son mandat. En corrélation avec son éthique
          et sa déontologie, M CONCIERGERIE protège les informations
          personnelles que ses Membres peuvent être amenés à lui communiquer
          dans le cadre de leur Abonnement.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0 48px" }}
        >
          Conformément aux obligations légales, et en conformité avec la CNIL, M
          CONCIERGERIE conserve ces informations dans les conditions légales.
          <br />
          Les informations personnelles collectées seront conservées aussi
          longtemps que nécessaire jusqu’à la fin de la durée de l’Abonnement
          souscrit par le Membre, sauf si :<br /> a) une durée de conservation
          plus longue est autorisée ou imposée par une disposition légale ou
          règlementaire ;<br />
          b) le Membre a exercé l’un des droits qui lui sont reconnus par la
          législation.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          Dans tous les cas, les données personnelles du Membre seront
          conservées pour une durée qui ne saurait excéder 36 mois à partir de
          la date de fin du dernier Abonnement souscrit par le Membre.
          <br /> L’accès aux données personnelles des Membres est strictement
          limité au personnel de M CONCIERGERIE habilité à les traiter en raison
          de ses fonctions. Les données personnelles ne sont utilisées que dans
          le cadre des finalités pour lesquelles les Membres les ont
          communiquées.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          L’utilisation de ces informations personnelles est réservée à un usage
          strictement interne (gestion des commandes, livraison, facturation,
          suivi de la solvabilité ainsi que toutes les études marketing interne
          et la publicité personnalisée). M CONCIERGERIE s’engage à ne pas
          vendre, louer, céder ou donner accès à des tiers aux données sans
          consentement préalable du Membre, à moins d’y être contraints en
          raison d’un motif légitime constitué par une contrainte légale ou
          judiciaire (obligation légale, lutte contre la fraude ou l’abus,
          exercice des droits de la défense, etc.).
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          Toutefois, le Membre autorise M CONCIERGERIE à partager ses
          informations personnelles avec les Partenaires de M CONCIERGERIE si
          cela est nécessaire en vue de la gestion des commandes, de la
          livraison, de la facturation, du suivi de la solvabilité ainsi que de
          toutes les études marketing interne et de la publicité personnalisée.
          En cas d’exercice, de la part du Membre, de son droit d’accès, de
          rectification, d’effacement, de limitation, de portabilité ou
          d’opposition au traitement de ses données pour un motif légitime, cela
          sera notifié aux Partenaires concernés.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
          style={{ margin: "48px 0" }}
        >
          Conformément à l’article 27 de la Loi Nº 78-17 du 6 janvier 1978
          modifiée, relative à l’informatique, aux fichiers et aux libertés, et
          au Règlement européen n°2016/679/UE du 27 avril 2016 (applicable dès
          le 25 mai 2018), les Membres disposent d’un droit d’accès, de
          rectification, d’effacement, de limitation et de portabilité, ainsi
          que d’un droit d’opposition au traitement de ces données pour un motif
          légitime.
        </Text>

        <Text
          fontSize="font18"
          fontWeight={400}
          color="white"
          textAlign="justify"
        >
          Ces droits peuvent être exercés par les Membres, justifiant de leur
          identité, par l’un des moyens suivants :<br /> – en écrivant à
          l’adresse suivante : 78, avenue des champs élysées , 75008 PARIS ;
          <br /> – par téléphone au 06 16 50 72 13 (du lundi au vendredi de 9
          heures à 18 heures et les jours fériés de 14 heures à 18 heures ;
          fermeture le week-end), appel non surtaxé
        </Text>
      </Container>
    </Layout>
  );
};

export default ConfidentialityPage;
