import styled from "styled-components";

const Template = styled.div`
  ${({
    theme: { fontSize, colors },
    $fontSize,
    $fontWeight,
    $color,
    $textAlign,
  }) => `
    font-size: ${fontSize[$fontSize]};
    font-weight: ${$fontWeight};
    color: ${colors[$color]};
    text-align: ${$textAlign};
    line-height: calc(${fontSize[$fontSize]} + 8px);
  `}
`;

const Text = ({
  as = "div",
  fontSize = "font14",
  fontWeight = 500,
  color = "grey",
  textAlign = "left",
  children,
  style,
  ...props
}) => {
  return (
    <Template
      as={as}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $color={color}
      $textAlign={textAlign}
      style={style}
      {...props}
    >
      {children}
    </Template>
  );
};

export default Text;
